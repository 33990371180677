console.log('🥑 %cMade by Monk', 'background: #616a2e; color: #f4e9e2; padding: 5px 17px; border-radius: 3px;');
console.log(' %chttp://monk.com.au ', 'padding: 5px 13px;');





jQuery(document).ready(function ($) {

        //page
    var $hamburger  = $(".hamburger"),
        $site       = $("html,body"),
        $content    = $('.site-content'),

        //menu
        $menu       = $(".main-navigation"),
        $submenu    = $(".main-navigation"),

        //media
        $lightbox   = $('.lightbox'),
        $slider     = $('.feature.slider');


    //mobile menu
    $hamburger.on("click", function () {
        $hamburger.toggleClass("is-active");
        $site.toggleClass("menu-open");
    });
    //close menu with an outside click (basically anywhere on .site-content)
    $content.on('click', function() {
        $site.removeClass("menu-open");
        $menu.removeClass("toggled");
        $submenu.removeClass('toggled-on');
        $hamburger.removeClass("is-active");
    });

    //add lightbox to image links
    $('a[href]').filter(function () {
        return this.href && this.href.match(/\.(?:jpe?g|gif|bmp|a?png)$/i);
    }).addClass('lightbox');
    $lightbox.swipebox();


    //feature slider
    $slider.slick({
        accessibility: true,
        autoplay: true,
        draggable: true,
        infinite: true,
        pauseOnHover: false,
        swipe: true,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        fade: true
    });

    $('.gform_wrapper').on('submit', 'form', function(){
        $('[type=submit]', this)        // Select the form's submit button
            .val('Sending...')         // Change the value of the submit button. Change text to whatever you like.
            .prop('disabled', true);    // Not really necessary but will prevent the user from clicking the button again while the form is submitting.
    });

    $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function (event) {
        // On-page links
        if (
            location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
            location.hostname == this.hostname
        ) {
            // Figure out element to scroll to
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            // Does a scroll target exist?
            if (target.length) {
                // Only prevent default if animation is actually gonna happen
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: target.offset().top
                }, 1000, function () {
                    // Callback after animation
                    // Must change focus!
                    var $target = $(target);
                    $target.focus();
                    if ($target.is(":focus")) { // Checking if the target was focused
                        return false;
                    } else {
                        $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                        $target.focus(); // Set focus again
                    };
                });
            }
        }
    });

    /*!
    ♡♡♡♡♡♡♡♡♡♡♡
    ♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥
    More accessible gravity forms - see forms/_fields.scss for the styles
    ♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥
    ♡♡♡♡♡♡♡♡♡♡♡
    */
//     var $fields = $( '.gform_body input[type="text"],.gform_body input[type="email"], .gform_body textarea' ),
//         $label = $( ".gfield_label" );

//     //wrap label with a span
//     $label.wrapInner( "<span class='label-content'></span>");

//     //Add an active class to a focused in input field
//     $fields.focusin(function() {
//         $(this).parents( ".gfield" ).addClass("active");
//     });

//     //Check if the field is filled - if it is the active class will stay, if not the active class will revert to its non active state
//     $fields.focusout(function() {
// //        console.log($(this).val().length);

//         if ($(this).val().length == 0 ) {
//             $(this).parents( ".gfield" ).removeClass("active");
//         }

//     });

//     //Pre-check fallback - checks if the field already has a value and adds an active class if it does
// //    $fields.each(function( index ) {
// //            console.log( index + ": " + $( this ).val() );
// //    });

//     $fields.each(function() {
//         if( $(this).val()) {
//             $(this).parents( ".gfield" ).addClass("active");
//         } else {
//             $(this).parents( ".gfield" ).removeClass("active");
//         };
//     });
});